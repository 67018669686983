import { withPrefix } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Normalize } from 'styled-normalize'
import Spritesheet from '../components/Spritesheet'
import GlobalStyle from '../styles/GlobalStyle'

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Whitney Lab Online</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={withPrefix('/apple-icon-57x57.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={withPrefix('/apple-icon-60x60.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={withPrefix('/apple-icon-72x72.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={withPrefix('/apple-icon-76x76.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={withPrefix('/apple-icon-114x114.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={withPrefix('/apple-icon-120x120.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={withPrefix('/apple-icon-144x144.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={withPrefix('/apple-icon-152x152.png')}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={withPrefix('/apple-icon-180x180.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={withPrefix('/android-icon-192x192.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={withPrefix('/favicon-32x32.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={withPrefix('/favicon-96x96.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={withPrefix('/favicon-16x16.png')}
        />
        <link rel="manifest" href={withPrefix('/manifest.json')} />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta
          name="msapplication-TileImage"
          content={withPrefix('/ms-icon-144x144.png')}
        />
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Normalize />
      <GlobalStyle />
      <Spritesheet />
    </>
  )
}

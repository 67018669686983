import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Button = ({ disabled, label, name, onClick, type, variant }) => {
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  return (
    <StyledButton
      className={`${variant} ${isDisabled ? 'disabled' : ''}`}
      disabled={isDisabled}
      name={name}
      onClick={onClick}
      type={type}
    >
      {label}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  --bg: var(--color-gray-600);
  --bgh: var(--color-gray-500);
  --fg: var(--color-black);

  &.primary {
    --bg: var(--color-blue-400);
    --bgh: var(--color-blue-500);
    --fg: var(--color-white);
  }

  &.error {
    --bg: var(--color-red-400);
    --bgh: var(--color-red-500);
    --fg: var(--color-white);
  }

  &.success {
    --bg: var(--color-green-400);
    --bgh: var(--color-green-500);
    --fg: var(--color-white);
  }

  &.warning {
    --bg: var(--color-yellow-400);
    --bgh: var(--color-yellow-500);
    --fg: var(--color-black);
  }

  &.disabled {
    --bg: var(--color-gray-700);
    --bgh: var(--color-gray-700);
    --fg: var(--color-gray-800);
    opacity: 0.9;

    &:hover {
      cursor: default !important;
    }
  }

  align-items: center;
  background-color: var(--bg);
  border: 0;
  border-radius: 20px;
  color: var(--fg);
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  isolation: isolate;
  justify-content: center;
  line-height: 1.3;
  margin: 0;
  padding: 8px 20px;
  transition: background-color 0.13s ease-out;
  vertical-align: top;
  -webkit-appearance: button;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;

  &:hover,
  &:focus {
    background-color: var(--bgh);
  }

  svg {
    height: 18px;
    margin-right: 4px;
    width: 18px;
  }
`

Button.defaultProps = {
  disabled: false,
  type: 'button',
  variant: '',
}

Button.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
}

export default Button

import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const InlineVideo = ({ poster, src, title }) => {
  const [preloading, setPreloading] = useState(true)
  const vidRef = useRef(null)

  const handleVideo = () => {
    if (vidRef.current.readyState >= 1) {
      setPreloading(false)
    }
  }

  useEffect(() => {
    const t = setTimeout(() => {
      if (!preloading) vidRef.current.play()
    }, 500)

    return () => {
      clearTimeout(t)
    }
  }, [preloading])

  useEffect(() => {
    if (vidRef.current !== null) {
      vidRef.current.addEventListener('loadstart', handleVideo)
      vidRef.current.addEventListener('progress', handleVideo)
      vidRef.current.addEventListener('canplay', handleVideo)
      vidRef.current.addEventListener('canplaythrough', handleVideo)
    }
    return () => {
      vidRef.current.removeEventListener('loadstart', handleVideo)
      vidRef.current.removeEventListener('progress', handleVideo)
      vidRef.current.removeEventListener('canplay', handleVideo)
      vidRef.current.removeEventListener('canplaythrough', handleVideo)
    }
  }, [vidRef.current])

  return (
    <StyledInlineVideo>
      <div aria-hidden={!preloading} hidden={!preloading}>
        <img alt="" src={poster} />
      </div>
      <div>
        <video
          loop
          muted
          playsInline
          preload="true"
          poster={poster}
          ref={vidRef}
          title={title}
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </StyledInlineVideo>
  )
}

const StyledInlineVideo = styled.div`
  display: grid;

  div {
    grid-column: 1;
    grid-row: 1;
  }
`

InlineVideo.propTypes = {
  poster: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default InlineVideo

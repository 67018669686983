import { withPrefix } from 'gatsby'
import React from 'react'
import InlineVideo from '../components/InlineVideo'
import StyledMast from '../styles/StyledMast'

const Mast = () => {
  return (
    <StyledMast aria-labelledby="mast-heading">
      <div className="mast-intro">
        <h2 id="mast-heading">
          welcome <span>to Whitney Lab Online</span>
        </h2>
      </div>
      <div className="mast-bg-video">
        <InlineVideo
          poster={withPrefix('/dswmedia/GettyImages-1160457378-poster.jpg')}
          src={withPrefix('/dswmedia/GettyImages-1160457378.mp4')}
        />
      </div>
      <div className="mast-indicator">
        <svg>
          <use xlinkHref="#clarity-caret-line" />
        </svg>
      </div>
    </StyledMast>
  )
}

export default Mast

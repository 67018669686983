import React from 'react'
import styled from 'styled-components'
import { getCurrentYear } from '../js/utils'

const Footer = () => (
  <StyledFooter>
    <p>Copyright Whitney Lab {getCurrentYear()} | All Rights Reserved</p>
  </StyledFooter>
)

const StyledFooter = styled.footer`
  align-items: center;
  background-color: var(--color-gray-100);
  color: var(--color-white);
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  padding: 1.5rem 3rem;
  text-align: center;

  @media screen and (min-width: 48em) {
    font-size: 1rem;
  }
`

export default Footer

import styled from 'styled-components'

const StyledMast = styled.section`
  background-color: var(--color-black);
  height: 100vh;
  overflow: hidden;
  position: relative;

  .mast-intro {
    align-items: center;
    animation: fade-in 550ms ease-in-out 1000ms forwards;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;

    h2 {
      color: var(--color-white);
      font-size: 4rem;
      line-height: 1.1;
      margin: 0;
      text-align: center;

      @media screen and (min-width: 48em) {
        font-size: 6rem;
      }

      span {
        display: block;
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.34375rem;
      }
    }
  }

  .mast-bg-video {
    height: 100vh;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: 10;

    > div {
      height: 100vh;
    }

    div > div > video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  @keyframes indicator {
    0% {
      opacity: 0;
      transform: translateY(0);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(2rem);
    }
  }

  .mast-indicator {
    align-items: center;
    animation: indicator 1000ms ease-in-out 2000ms infinite;
    bottom: 2.5rem;
    display: flex;
    justify-content: center;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 20;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
`

export default StyledMast

import PropTypes from 'prop-types'
import React from 'react'
import StyledHeader from '../styles/StyledHeader'

const Header = ({ setSignedIn, signedIn }) => {
  return (
    <StyledHeader>
      <h1>
        Whitney Lab <span>Online</span>
      </h1>
      {/* TODO: change href based on signed in status */}
      <a
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {
          e.preventDefault()
          setSignedIn(!signedIn)
        }}
      >
        Sign {signedIn ? 'Out' : 'In'}
      </a>
    </StyledHeader>
  )
}

Header.propTypes = {
  setSignedIn: PropTypes.func,
  signedIn: PropTypes.bool,
}

export default Header

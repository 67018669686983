import styled from 'styled-components'

const StyledHeader = styled.header`
  align-items: center;
  background-color: var(--color-gray-100);
  border-bottom: 1px solid hsla(0, 0%, 81%, 0.33);
  display: flex;
  height: 4rem;
  justify-content: space-between;
  left: 0;
  padding: 0 1.25rem;
  position: fixed;
  top: 0;
  transition: all 225ms ease-in-out;
  width: 100%;
  z-index: 1000;

  @media screen and (min-width: 48em) {
    background-color: hsla(0, 0%, 0%, 0.75);
  }

  h1 {
    align-items: center;
    color: var(--color-white);
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    justify-content: flex-start;
    margin: 0;
    max-width: 32ch;
    text-transform: uppercase;

    @media screen and (min-width: 48em) {
      font-size: 1.25rem;
    }

    svg {
      margin-right: 0.625rem;
      height: 2rem;
      width: 1.75rem;
    }

    span {
      display: inline-block;
      font-weight: 400;
      padding-left: 4px;
    }
  }

  a {
    color: var(--color-white);
    font-size: 0.8rem;
    text-decoration: none;
    text-transform: uppercase;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  &.fill {
    background-color: var(--color-white);
    transition: color 0.13s ease-in-out;

    h1,
    a {
      color: var(--color-black);
    }

    a:hover,
    a:focus {
      color: var(--color-blue-400);
    }
  }
`

export default StyledHeader

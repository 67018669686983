import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { version } from '../../package.json';
import Footer from '../components/Footer'
import Header from '../components/Header'
import HomeMain from '../components/HomeMain'
import Mast from '../components/Mast'
import StyledIndex from '../styles/StyledIndex'
import HomePage from '../templates/Home'

export default function Home(props) {
  const [signedIn, setSignedIn] = useState(true)

  // !!@ Should we filter for access here?
  console.log('Home version', version, 'pathname', props.location.pathname)

  return (
    <StyledIndex>
      <HomePage pathName={props.location.pathname} />
      <Header setSignedIn={setSignedIn} signedIn={signedIn} />
      <Mast />
      <HomeMain signedIn={signedIn} location={props.location} />
      <Footer />
    </StyledIndex>
  )
}

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

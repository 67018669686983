import React from 'react'

const Spritesheet = () => (
  <svg style={{ display: 'none' }}>
    <symbol id="whitney-w" viewBox="0 0 200 200" fill="none">
      <path
        d="M164.497 55C159.521 55 152.216 59.8184 148.067 66.6257C153.713 62.8059 156.199 57.4924 164.164 57.4924C175.72 57.4924 185.276 79.1988 195.19 100.871C195.19 100.871 178.244 141.706 162.395 141.706C146.298 141.706 132.358 55 98.3391 55C93.3586 55 86.0585 59.8184 81.9095 66.6257C87.5509 62.8059 90.0369 57.4924 98.0065 57.4924C109.558 57.4924 119.114 79.1988 129.028 100.871C125.548 108.767 110.185 141.706 96.2369 141.706C80.1356 141.706 66.1962 55 32.177 55C27.1965 55 19.8964 59.8184 15.7474 66.6257C21.3888 62.8059 23.8791 57.4924 31.8444 57.4924C54.9133 57.4924 70.0126 144.028 95.9043 144.028C111.328 144.028 126.606 110.465 129.889 102.749C139.501 123.7 149.504 144.028 162.062 144.028C179.49 144.028 196.145 102.527 196.145 102.527L199.983 93.4791C190.606 73.4457 180.3 55 164.497 55Z"
        fill="currentColor"
      />
      <path
        d="M37.1575 116.291C32.4073 124.596 29.8488 139.128 22.054 139.128C17.9775 139.128 17.3635 138.573 17.3635 138.573C21.4997 141.928 26.0069 144.028 30.4458 144.028C43.1444 144.028 56.4186 125.424 56.4186 102.087C48.611 102.087 41.8865 107.986 37.1575 116.291ZM20.8643 139.986C31.8743 141.732 36.4923 128.288 39.4772 120.503C42.4621 112.719 49.4382 103.752 54.747 103.752C52.922 113.051 44.0142 150.148 20.8643 139.986Z"
        fill="currentColor"
      />
      <path
        d="M25.9258 134.95C16.7964 133.123 11.1507 122.851 11.1507 119.863C11.1507 116.875 12.6772 113.798 14.0034 111.972C15.3295 110.145 14.9159 109.232 14.0034 109.898C13.0908 110.563 9.85438 112.885 7.44942 112.885C4.62657 112.885 2.0553 107.405 1.14278 106.056C0.230259 104.708 0 105.566 0 107.486C0 111.195 6.8226 133.358 17.3678 136.888C22.054 138.462 25.0389 136.055 25.9258 134.95Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="whitney-logo-circle-black" viewBox="0 0 260 260" fill="none">
      <rect fill="#fff" />
      <circle cx="130.5" cy="130.5" r="128.5" fill="#010101" />
      <path
        d="M194.514 85c-4.976 0-12.281 4.818-16.43 11.626 5.646-3.82 8.132-9.134 16.097-9.134 11.556 0 21.112 21.707 31.026 43.379 0 0-16.945 40.835-32.795 40.835-16.097 0-30.037-86.706-64.056-86.706-4.98 0-12.28 4.818-16.429 11.626 5.641-3.82 8.127-9.134 16.097-9.134 11.551 0 21.107 21.707 31.021 43.379-3.479 7.896-18.843 40.835-32.791 40.835-16.101 0-30.04-86.706-64.06-86.706-4.98 0-12.28 4.818-16.43 11.626 5.642-3.82 8.132-9.134 16.097-9.134 23.07 0 38.169 86.536 64.06 86.536 15.424 0 30.702-33.563 33.985-41.279 9.612 20.951 19.615 41.279 32.173 41.279 17.428 0 34.083-41.501 34.083-41.501l3.838-9.048C220.623 103.446 210.317 85 194.514 85z"
        fill="#fff"
      />
      <path
        d="M67.175 146.291c-4.75 8.305-7.31 22.837-15.104 22.837-4.076 0-4.69-.555-4.69-.555 4.136 3.355 8.643 5.455 13.082 5.455 12.698 0 25.973-18.604 25.973-41.941-7.808 0-14.533 5.899-19.261 14.204zM50.88 169.986c11.01 1.746 15.628-11.698 18.613-19.483 2.985-7.784 9.961-16.751 15.27-16.751-1.825 9.299-10.733 46.396-33.883 36.234z"
        fill="#fff"
      />
      <path
        d="M55.943 164.95c-9.13-1.827-14.775-12.099-14.775-15.087 0-2.988 1.526-6.065 2.852-7.891 1.327-1.827.913-2.74 0-2.074-.912.665-4.149 2.987-6.553 2.987-2.823 0-5.395-5.48-6.307-6.829-.913-1.348-1.143-.49-1.143 1.43 0 3.709 6.823 25.872 17.368 29.402 4.686 1.574 7.671-.833 8.558-1.938z"
        fill="#fff"
      />
    </symbol>
    <symbol id="whitney-logo-circle-white" viewBox="0 0 260 260" fill="none">
      <rect fill="#010101" />
      <circle cx="130.5" cy="130.5" r="128.5" fill="#fff" />
      <path
        d="M194.514 85c-4.976 0-12.281 4.818-16.43 11.626 5.646-3.82 8.132-9.134 16.097-9.134 11.556 0 21.112 21.707 31.026 43.379 0 0-16.945 40.835-32.795 40.835-16.097 0-30.037-86.706-64.056-86.706-4.98 0-12.28 4.818-16.429 11.626 5.641-3.82 8.127-9.134 16.097-9.134 11.551 0 21.107 21.707 31.021 43.379-3.479 7.896-18.843 40.835-32.791 40.835-16.101 0-30.04-86.706-64.06-86.706-4.98 0-12.28 4.818-16.43 11.626 5.642-3.82 8.132-9.134 16.097-9.134 23.07 0 38.169 86.536 64.06 86.536 15.424 0 30.702-33.563 33.985-41.279 9.612 20.951 19.615 41.279 32.173 41.279 17.428 0 34.083-41.501 34.083-41.501l3.838-9.048C220.623 103.446 210.317 85 194.514 85z"
        fill="#010101"
      />
      <path
        d="M67.175 146.291c-4.75 8.305-7.31 22.837-15.104 22.837-4.076 0-4.69-.555-4.69-.555 4.136 3.355 8.643 5.455 13.082 5.455 12.698 0 25.973-18.604 25.973-41.941-7.808 0-14.533 5.899-19.261 14.204zM50.88 169.986c11.01 1.746 15.628-11.698 18.613-19.483 2.985-7.784 9.961-16.751 15.27-16.751-1.825 9.299-10.733 46.396-33.883 36.234z"
        fill="#010101"
      />
      <path
        d="M55.943 164.95c-9.13-1.827-14.775-12.099-14.775-15.087 0-2.988 1.526-6.065 2.852-7.891 1.327-1.827.913-2.74 0-2.074-.912.665-4.149 2.987-6.553 2.987-2.823 0-5.395-5.48-6.307-6.829-.913-1.348-1.143-.49-1.143 1.43 0 3.709 6.823 25.872 17.368 29.402 4.686 1.574 7.671-.833 8.558-1.938z"
        fill="#010101"
      />
    </symbol>
    <symbol id="whitney-logo-white" viewBox="0 0 1121 499" fill="none">
      <path
        d="M922.101 0c-27.892 0-68.833 27.007-92.088 65.162 31.644-21.41 45.578-51.192 90.224-51.192 64.77 0 118.333 121.664 173.903 243.137 0 0-94.984 228.88-183.822 228.88C820.094 485.987 741.964 0 551.286 0c-27.915 0-68.833 27.007-92.088 65.162 31.62-21.41 45.554-51.192 90.224-51.192 64.746 0 118.307 121.664 173.876 243.137-19.503 44.255-105.616 228.88-183.794 228.88C449.256 485.987 371.125 0 180.448 0c-27.916 0-68.833 27.007-92.088 65.162 31.62-21.41 45.578-51.192 90.224-51.192C307.885 13.97 392.516 499 537.639 499c86.448 0 172.083-188.118 190.486-231.368C781.997 385.062 838.067 499 908.454 499c97.676 0 191.036-232.612 191.036-232.612l21.51-50.713C1068.44 103.388 1010.68 0 922.101 0z"
        fill="#fff"
      />
      <path
        d="M208.364 343.535c-26.625 46.551-40.966 128.003-84.656 128.003-22.848 0-26.29-3.11-26.29-3.11C120.601 487.231 145.864 499 170.744 499c71.176 0 145.578-104.273 145.578-235.075-43.762 0-81.453 33.059-107.958 79.61zM117.04 476.346c61.711 9.784 87.595-65.568 104.325-109.201 16.731-43.632 55.832-93.891 85.588-93.891-10.23 52.125-60.158 260.049-189.913 203.092z"
        fill="#fff"
      />
      <path
        d="M145.41 448.119c-51.17-10.238-82.815-67.817-82.815-84.562 0-16.745 8.556-33.992 15.99-44.23 7.433-10.239 5.114-15.358 0-11.626-5.115 3.731-23.256 16.745-36.735 16.745-15.823 0-30.234-30.715-35.35-38.275-5.114-7.559-6.404-2.75-6.404 8.014 0 20.788 38.24 145.012 97.346 164.795 26.266 8.827 42.997-4.665 47.968-10.861z"
        fill="#fff"
      />
    </symbol>
    <symbol id="whitney-logo-black" viewBox="0 0 1121 499" fill="none">
      <path
        d="M922.101 0c-27.892 0-68.833 27.007-92.088 65.162 31.644-21.41 45.578-51.192 90.224-51.192 64.77 0 118.333 121.664 173.903 243.137 0 0-94.984 228.88-183.822 228.88C820.094 485.987 741.964 0 551.286 0c-27.915 0-68.833 27.007-92.088 65.162 31.62-21.41 45.554-51.192 90.224-51.192 64.746 0 118.307 121.664 173.876 243.137-19.503 44.255-105.616 228.88-183.794 228.88C449.256 485.987 371.125 0 180.448 0c-27.916 0-68.833 27.007-92.088 65.162 31.62-21.41 45.578-51.192 90.224-51.192C307.885 13.97 392.516 499 537.639 499c86.448 0 172.083-188.118 190.486-231.368C781.997 385.062 838.067 499 908.454 499c97.676 0 191.036-232.612 191.036-232.612l21.51-50.713C1068.44 103.388 1010.68 0 922.101 0z"
        fill="#010101"
      />
      <path
        d="M208.364 343.535c-26.625 46.551-40.966 128.003-84.656 128.003-22.848 0-26.29-3.11-26.29-3.11C120.601 487.231 145.864 499 170.744 499c71.176 0 145.578-104.273 145.578-235.075-43.762 0-81.453 33.059-107.958 79.61zM117.04 476.346c61.711 9.784 87.595-65.568 104.325-109.201 16.731-43.632 55.832-93.891 85.588-93.891-10.23 52.125-60.158 260.049-189.913 203.092z"
        fill="#010101"
      />
      <path
        d="M145.41 448.119c-51.17-10.238-82.815-67.817-82.815-84.562 0-16.745 8.556-33.992 15.99-44.23 7.433-10.239 5.114-15.358 0-11.626-5.115 3.731-23.256 16.745-36.735 16.745-15.823 0-30.234-30.715-35.35-38.275-5.114-7.559-6.404-2.75-6.404 8.014 0 20.788 38.24 145.012 97.346 164.795 26.266 8.827 42.997-4.665 47.968-10.861z"
        fill="#010101"
      />
    </symbol>
    <symbol
      id="arrow-right"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      style={{ verticalAlign: 'middle' }}
      height="1em"
      width="1em"
    >
      <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
    </symbol>
    <symbol
      id="arrow-right-tan"
      stroke="currentColor"
      fill="#beab97"
      strokeWidth="0"
      viewBox="0 0 24 24"
      style={{ verticalAlign: 'middle' }}
      height="1em"
      width="1em"
    >
      <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
    </symbol>
    <symbol
      id="arrow-left"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      style={{ verticalAlign: 'middle' }}
      height="1em"
      width="1em"
    >
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
    </symbol>
    <symbol
      id="arrow-left-tan"
      stroke="currentColor"
      fill="#beab97"
      strokeWidth="0"
      viewBox="0 0 24 24"
      style={{ verticalAlign: 'middle' }}
      height="1em"
      width="1em"
    >
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
    </symbol>
    <symbol id="menu-icon" viewBox="0 0 24 24" fill="none">
      <path
        d="M13 18H20M4 6H20H4ZM4 12H20H4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>
    <symbol id="close-icon" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.41 12L17.71 7.71C17.8983 7.5217 18.0041 7.2663 18.0041 7C18.0041 6.7337 17.8983 6.47831 17.71 6.29C17.5217 6.1017 17.2663 5.99591 17 5.99591C16.7337 5.99591 16.4783 6.1017 16.29 6.29L12 10.59L7.71 6.29C7.5217 6.1017 7.2663 5.99591 7 5.99591C6.7337 5.99591 6.4783 6.1017 6.29 6.29C6.1017 6.47831 5.99591 6.7337 5.99591 7C5.99591 7.2663 6.1017 7.5217 6.29 7.71L10.59 12L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L12 13.41L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29L13.41 12Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="icon-success" viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, -2.000000)">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
            id="Shape"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </symbol>
    <symbol id="icon-error" viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, -2.000000)">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,15 L13,15 L13,17 Z M13,13 L11,13 L11,7 L13,7 L13,13 Z"
            id="Shape"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </symbol>
    <symbol id="icon-info" viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, -2.000000)">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,11 L13,11 L13,17 Z M13,9 L11,9 L11,7 L13,7 L13,9 Z"
            id="Shape"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </symbol>
    <symbol id="icon-warning" viewBox="0 0 22 19">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -2.000000)">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M1,21 L23,21 L12,2 L1,21 Z M13,18 L11,18 L11,16 L13,16 L13,18 Z M13,14 L11,14 L11,10 L13,10 L13,14 Z"
            id="Shape"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </symbol>
    <symbol id="icon-check-circle" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.9219 5.51562H10.1891C10.0297 5.51562 9.87812 5.59219 9.78437 5.72344L7.32812 9.12969L6.21562 7.58594C6.12187 7.45625 5.97187 7.37813 5.81094 7.37813H5.07812C4.97656 7.37813 4.91719 7.49375 4.97656 7.57656L6.92344 10.2766C6.96943 10.3408 7.03006 10.3931 7.1003 10.4291C7.17054 10.4652 7.24838 10.4841 7.32734 10.4841C7.40631 10.4841 7.48414 10.4652 7.55439 10.4291C7.62463 10.3931 7.68526 10.3408 7.73125 10.2766L11.0219 5.71406C11.0828 5.63125 11.0234 5.51562 10.9219 5.51562V5.51562Z"
        fill="currentColor"
      />
      <path
        d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="icon-check" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.97 4.97C11.11 4.83127 11.2989 4.75319 11.4959 4.75265C11.693 4.75211 11.8823 4.82914 12.0231 4.96709C12.1638 5.10505 12.2445 5.29284 12.2479 5.48987C12.2513 5.68689 12.1769 5.87733 12.041 6.02L8.04901 11.01C7.9804 11.0839 7.89759 11.1432 7.80554 11.1844C7.71348 11.2255 7.61408 11.2477 7.51326 11.2496C7.41244 11.2514 7.31228 11.233 7.21876 11.1952C7.12525 11.1575 7.0403 11.1013 6.96901 11.03L4.32401 8.384C4.25032 8.31534 4.19122 8.23254 4.15022 8.14054C4.10923 8.04854 4.08719 7.94922 4.08541 7.84852C4.08364 7.74782 4.10216 7.64779 4.13988 7.5544C4.1776 7.46101 4.23375 7.37618 4.30497 7.30496C4.37619 7.23374 4.46102 7.1776 4.55441 7.13988C4.6478 7.10215 4.74782 7.08363 4.84853 7.08541C4.94923 7.08718 5.04854 7.10923 5.14054 7.15022C5.23254 7.19121 5.31534 7.25031 5.384 7.324L7.47801 9.417L10.951 4.992C10.9572 4.98425 10.9639 4.9769 10.971 4.97H10.97Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="clarity-caret-line"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.23998 6.74001L8.99998 12.7L14.76 6.74001C14.9165 6.57757 15.002 6.35962 14.9978 6.1341C14.9936 5.90859 14.8999 5.69399 14.7375 5.53751C14.575 5.38103 14.3571 5.2955 14.1316 5.29972C13.9061 5.30394 13.6915 5.39757 13.535 5.56001L8.99998 10.255L4.45998 5.56001C4.3035 5.39757 4.0889 5.30394 3.86339 5.29972C3.63788 5.2955 3.41992 5.38103 3.25748 5.53751C3.09503 5.69399 3.0014 5.90859 2.99718 6.1341C2.99296 6.35962 3.0785 6.57757 3.23498 6.74001H3.23998Z"
        fill="white"
      />
    </symbol>
    <symbol id="icon-lock-closed" viewBox="0 0 18 18">
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        fill="currentcolor"
        d="M14.5,8H14V7A5,5,0,0,0,4,7V8H3.5a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-8A.5.5,0,0,0,14.5,8ZM6,7a3,3,0,0,1,6,0V8H6Zm4,6.111V14.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13.111a1.5,1.5,0,1,1,2,0Z"
      />
    </symbol>
  </svg>
)

export default Spritesheet

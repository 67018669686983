export const getViewportHeight = () => {
  return Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  )
}

export const getCurrentYear = () => {
  const d = new Date()
  return d.getFullYear()
}

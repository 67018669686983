/* eslint-disable max-len */
import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import slugify from 'slugify'
import { v4 as uuidv4 } from 'uuid'
import Button from '../components/Button'
import StyledHomeMain from '../styles/StyledHomeMain'

let http_home = 'https://k12campus.net/whitney/modules/'
let whref = typeof window !== 'undefined' && window.location.href
if (whref) {
  if (
    whref.startsWith('http://whitneylabonline.org/whitney/menu/') ||
    whref.startsWith('https://whitneylabonline.org/whitney/menu/') ||
    whref.startsWith('https://whitneylabonline.org') ||
    whref.startsWith('http://localhost:')
  ) {
    // http_home = 'https://whitneylabonline.org/whitney/modules-dev/'
    http_home = 'https://whitneylabonline.org/modules/'
  } else if (whref.startsWith('https://k12campus.net/whitney/menu/')) {
    http_home = 'https://k12campus.net/whitney/modules-dev/'
  }
}

const data = [
  {
    alt: 'mud snails underwater near a clam shell',
    caption: 'Inside the World of the Mud Snail',
    label: 'LAUNCH',
    src: withPrefix('/dswmedia/inside-the-world-of-the-mud-snail.jpg'),
    url: http_home + 'mud-snails/',
  },
  {
    alt: 'an estuary on a sunny day',
    caption: 'Microscopic Life in the Estuary',
    label: 'LAUNCH',
    src: withPrefix('/dswmedia/microscope-life-in-the-estuary.jpg'),
    url: http_home + 'microscopic-life-in-the-estuary/',
  },
  {
    alt: 'a group of people working to clean up trash at a beach',
    caption: 'Conservation Station',
    disabled: true,
    label: 'COMING SOON',
    // label: 'LAUNCH',
    src: withPrefix('/dswmedia/conservation-station.jpg'),
    url: http_home + 'conservation-station/',
  },
  {
    alt: 'a small sea turtle crawling to the ocean',
    caption: 'Journey of the Sea Turtle',
    disabled: true,
    label: 'COMING SOON',
    src: withPrefix('/dswmedia/journey-of-the-sea-turtle.jpg'),
    url: '',
  },
]

// const data_dict = {
//   mud: data[0],
//   microscopic: data[1],
//   conservation: data[2],
//   journey: data[3],
// }

function parseQueryString(location_search) {
  // search: parse url parameters
  // http://codereview.stackexchange.com/questions/9574/faster-and-cleaner-way-to-parse-parameters-from-url-in-javascript-jquery
  const query = (location_search || '?').substr(1)
  const map = {}
  query.replace(/([^&=]+)=?([^&]*)(?:&+|$)/g, function (match, key, value) {
    if (!map[key]) map[key] = []
    map[key].push(value)
  })
  return map
}

function parseAccessCode(location_search) {
  // const searchParams = new URL(props.location.href).searchParams
  const qdict = parseQueryString(location_search)
  let amodule = qdict['amodule']
  if (amodule) amodule[0]
  let acode = qdict['acode']
  if (acode) acode = acode[0]
  console.log('amodule', amodule)
  console.log('acode', acode)
  return acode
}

const HomeMain = props => {
  const { signedIn, performAccessCheck = false } = props

  console.log('HomeMain wlo-module-launcher')
  // const searchParams = new URL(window.location.href).searchParams
  // const amodule = searchParams.get('amodule')
  // const acode = searchParams.get('acode')
  // console.log('amodule', amodule)
  // console.log('acode', acode)

  const location_search = props.location.search
  const acode = parseAccessCode(location_search)
  let access_suffix = '';

  console.log('HomeMain location_search', location_search)
  console.log('acode', acode)

  if (performAccessCheck) {
    access_suffix = '?acode=' + (acode || '');
  }
  // if (acode && amodule) {
  //   const ent = data_dict[amodule]
  //   console.log('ent', ent)
  //   if (ent) {
  //     // setTimeout(() => {
  //     //   window.open(ent.url + '?acode=' + acode, '_blank')
  //     // }, 500)
  //   }
  // }

  const createMarkup = s => {
    return {
      __html: s,
    }
  }

  const handleLaunch = url => {
    window.open(url + access_suffix, '_blank')
  }

  return (
    <StyledHomeMain aria-labelledby="main-heading">
      <h2 id="main-heading">
        Welcome to Whitney Lab Online, where you can be a scientist for a day!
      </h2>
      <p className="main-intro">
        The University of Florida Whitney Laboratory for Marine Bioscience
        provides a K-12 educational outreach program to engage students in
        lifelong learning and support STEM education in our schools.
      </p>
      <div className="main-course-listing">
        {data.map(({ alt, caption, disabled, label, src, url }) => (
          <div key={uuidv4()}>
            <figure>
              <img src={src} alt={alt} />
              <figcaption>
                <span dangerouslySetInnerHTML={createMarkup(caption)} />
                <Button
                  disabled={!signedIn ? true : disabled}
                  label={
                    <>
                      {!signedIn || disabled ? (
                        <svg>
                          <use xlinkHref="#icon-lock-closed" />
                        </svg>
                      ) : (
                        ''
                      )}
                      {label}
                    </>
                  }
                  onClick={() => handleLaunch(url)}
                  name={slugify(caption)}
                  variant="primary"
                />
              </figcaption>
            </figure>
          </div>
        ))}
      </div>
    </StyledHomeMain>
  )
}

HomeMain.propTypes = {
  signedIn: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    href: PropTypes.string,
    search: PropTypes.string,
  }),
  performAccessCheck: PropTypes.bool,
}

export default HomeMain

import styled from 'styled-components'

const StyledHomeMain = styled.section`
  background-color: var(--color-white);
  margin: 0 auto;
  max-width: 82.5rem;
  min-height: calc(100vh - 160px);
  padding: 6rem 1.5rem;

  h2 {
    color: var(--color-gray-400);
    font-size: 28px;
    line-height: 1.3;
    margin: 0 auto 3rem;
    max-width: 700px;
    text-align: center;

    @media screen and (min-width: 35em) {
      font-size: 2.25rem;
      line-height: 1.5;
    }
  }

  .main-intro {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.8;
    margin: 0 auto 3rem;
    max-width: 75ch;
    text-align: center;
  }

  .main-course-listing {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
    margin: 5rem auto 0;
    max-width: 870px;

    figure {
      margin: 0;

      img {
        vertical-align: middle;
      }

      figcaption {
        align-items: center;
        background-color: var(--color-gray-900);
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        font-weight: 500;
        justify-content: center;
        letter-spacing: 2.5%;
        line-height: 1.2;
        margin: 0;
        padding: 0.75rem 0.5rem;
        text-align: center;
        text-transform: uppercase;

        button {
          margin-top: 0.5rem;
        }
      }
    }

    @media screen and (min-width: 36.25em) {
      font-size: 0.75rem;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      max-width: 36rem;
    }

    @media screen and (min-width: 48em) {
      max-width: 45rem;
    }
  }
`

export default StyledHomeMain
